/**
 * Return the codes of TTS voices of language interpreter (sitepal)
 * https://www.sitepal.com/docs/SitePal_TTS_Voices.pdf
 * @param {string} value language code
 * @returns {array} [language code, voice code, engine code]
 */
function getAvatarVoice(value) {
    if (value.match(/spanish/i)) {
        return [2, 1, 1];
    }

    if (value.match(/english/i)) {
        return [1, 1, 1];
    }

    if (value.match(/arabic/i)) {
        return [27, 2, 2];
    }

    if (value.match(/afrikaans/i)) {
        // 3rd party
        return [47, 1, 1];
    }

    if (value.match(/albanian/i)) {
        // 3rd party
        return [53, 1, 1];
    }

    if (value.match(/amharic/i)) {
        // 3rd party
        return [54, 1, 1];
    }

    if (value.match(/armenian/i)) {
        // 3rd party
        return [54, 1, 1];
    }

    if (value.match(/azerbaijani/i)) {
        // 3rd party
        return [56, 1, 1];
    }

    if (value.match(/basque/i)) {
        return [22, 1, 1];
    }

    if (value.match(/bengali/i)) {
        // 3rd party
        return [33, 1, 1];
    }

    if (value.match(/bosnian/i)) {
        // 3rd party
        return [57, 1, 1];
    }

    if (value.match(/bulgarian/i)) {
        // 3rd party
        return [42, 1, 1];
    }

    if (value.match(/burmese/i)) {
        // 3rd party
        return [58, 1, 1];
    }

    if (value.match(/catalan/i)) {
        return [5, 1, 1];
    }

    if (value.match(/chinese/i)) {
        return [10, 2, 2];
    }

    if (value.match(/croatian/i)) {
        // 3rd party
        return [43, 1, 1];
    }

    if (value.match(/czech/i)) {
        return [18, 1, 1];
    }

    if (value.match(/danish/i)) {
        return [19, 1, 1];
    }

    if (value.match(/dutch/i)) {
        return [11, 1, 1];
    }

    if (value.match(/esperanto/i)) {
        return [31, 1, 1];
    }

    if (value.match(/estonian/i)) {
        // 3rd party
        return [59, 1, 1];
    }

    if (value.match(/filipino/i)) {
        return [32, 1, 1];
    }

    if (value.match(/finnish/i)) {
        return [23, 1, 1];
    }

    if (value.match(/french/i)) {
        return [4, 1, 1];
    }

    if (value.match(/gaelic/i)) {
        // 3rd party
        return [60, 1, 1];
    }

    if (value.match(/galician/i)) {
        return [15, 1, 1];
    }

    if (value.match(/georgian/i)) {
        // 3rd party
        return [61, 1, 1];
    }

    if (value.match(/german/i)) {
        return [3, 1, 1];
    }

    if (value.match(/greek/i)) {
        return [8, 1, 1];
    }

    if (value.match(/gujarati/i)) {
        // 3rd party
        return [34, 1, 1];
    }

    if (value.match(/hebrew/i)) {
        // 3rd party
        return [44, 1, 1];
    }

    if (value.match(/hindi/i)) {
        return [24, 1, 1];
    }

    if (value.match(/hungarian/i)) {
        return [29, 1, 1];
    }

    if (value.match(/icelandic/i)) {
        // 3rd party
        return [25, 1, 1];
    }

    if (value.match(/indonesian/i)) {
        return [28, 1, 1];
    }

    if (value.match(/italian/i)) {
        return [7, 1, 1];
    }

    if (value.match(/japanese/i)) {
        return [12, 1, 1];
    }

    if (value.match(/javanese/i)) {
        // 3rd party
        return [62, 1, 1];
    }

    if (value.match(/kazakh/i)) {
        // 3rd party
        return [63, 1, 1];
    }

    if (value.match(/khmer/i)) {
        // 3rd party
        return [64, 1, 1];
    }

    if (value.match(/korean/i)) {
        return [13, 1, 1];
    }

    if (value.match(/lao/i)) {
        // 3rd party
        return [65, 1, 1];
    }

    if (value.match(/latvian/i)) {
        // 3rd party
        return [48, 1, 1];
    }

    if (value.match(/lithuanian/i)) {
        // 3rd party
        return [49, 1, 1];
    }

    if (value.match(/macedonian/i)) {
        // 3rd party
        return [66, 1, 1];
    }

    if (value.match(/malayalam/i)) {
        // 3rd party
        return [36, 1, 1];
    }

    if (value.match(/malay/i)) {
        // 3rd party
        return [45, 1, 1];
    }

    if (value.match(/maltese/i)) {
        // 3rd party
        return [67, 1, 1];
    }

    if (value.match(/marathi/i)) {
        // 3rd party
        return [67, 1, 1];
    }

    if (value.match(/mongolian/i)) {
        // 3rd party
        return [68, 1, 1];
    }

    if (value.match(/nepali/i)) {
        // 3rd party
        return [69, 1, 1];
    }

    if (value.match(/norwegian/i)) {
        return [20, 1, 1];
    }

    if (value.match(/pashto/i)) {
        // 3rd party
        return [70, 1, 1];
    }

    if (value.match(/persian/i)) {
        // 3rd party
        return [71, 1, 1];
    }

    if (value.match(/polish/i)) {
        return [14, 1, 1];
    }

    if (value.match(/portuguese/i)) {
        return [6, 1, 1];
    }

    if (value.match(/punjabi/i)) {
        // 3rd party
        return [51, 1, 1];
    }

    if (value.match(/romanian/i)) {
        return [30, 1, 1];
    }

    if (value.match(/russian/i)) {
        return [21, 1, 1];
    }

    if (value.match(/serbian/i)) {
        // 3rd party
        return [52, 1, 1];
    }

    if (value.match(/sinhala/i)) {
        // 3rd party
        return [72, 1, 1];
    }

    if (value.match(/slovak/i)) {
        return [37, 1, 1];
    }

    if (value.match(/slovenian/i)) {
        // 3rd party
        return [46, 1, 1];
    }

    if (value.match(/somali/i)) {
        // 3rd party
        return [73, 1, 1];
    }

    if (value.match(/sundanese/i)) {
        // 3rd party
        return [74, 1, 1];
    }

    if (value.match(/swahili/i)) {
        // 3rd party
        return [75, 1, 1];
    }

    if (value.match(/swedish/i)) {
        return [9, 1, 1];
    }

    if (value.match(/tamil/i)) {
        // 3rd party
        return [38, 1, 1];
    }

    if (value.match(/telugu/i)) {
        // 3rd party
        return [39, 1, 1];
    }

    if (value.match(/thai/i)) {
        return [26, 1, 1];
    }

    if (value.match(/turkish/i)) {
        return [16, 1, 1];
    }

    if (value.match(/ukrainian/i)) {
        return [40, 1, 1];
    }

    if (value.match(/urdu/i)) {
        // 3rd party
        return [76, 1, 1];
    }

    if (value.match(/uzbek/i)) {
        // 3rd party
        return [77, 1, 1];
    }

    if (value.match(/vietnamese/i)) {
        return [41, 1, 1];
    }

    if (value.match(/welsh/i)) {
        // 3rd party
        return [78, 1, 1];
    }

    if (value.match(/zulu/i)) {
        // 3rd party
        return [79, 1, 1];
    }

    return [1, 1, 1];
}

export { getAvatarVoice };
