import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import CallIcon from '@mui/icons-material/Call';

import LanguageButton from '../theme/LanguageButton';
import ButtonPhone from './common/ButtonPhone';

import Twillio from '../utils/twilio';

import './call.css';

function TwillioCall() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [state, setState] = useState('init');

    useEffect(() => {
        Twillio.connect();
    }, []);

    const handlePhoneNumber = (digit) => {
        setPhoneNumber(`${phoneNumber}${digit}`);
    };

    const handleDeleteLastDigit = () => {
        setPhoneNumber(phoneNumber.slice(0, -1));
    };

    const handleHangup = () => {
        setState('init');
        Twillio.disconnect();
    };

    const handleCall = async () => {
        setState('calling');
        const call = await Twillio.call(phoneNumber);

        call.on('reject', () => {
            setState('init');
        });
        call.on('error', () => {
            setState('init');
        });
        call.on('disconnect', () => {
            setState('init');
        });
    };

    return (
        <div className="App">
            <main className="App-main">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: 'calc(80vw - 6em)',
                            height: 'auto',
                        },
                    }}
                    className="wrapper-paper"
                >
                    <Paper elevation={3} sx={{ padding: '1em 2em' }}>
                        <h1 className="title-welcome">Direct call</h1>
                        <Box mt={5}>
                            <p style={{ fontSize: 14 }}>Phone Number</p>
                            <LanguageButton
                                variant="outlined"
                                // startIcon={
                                //     <img
                                //         src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1024px-Flag_of_the_United_States.svg.png"
                                //         width={20}
                                //         alt="bandera"
                                //         onClick={() => setOpenDialog(true)}
                                //     />
                                // }
                                endIcon={
                                    <BackspaceOutlinedIcon
                                        onClick={handleDeleteLastDigit}
                                        style={{ marginRight: 5 }}
                                    />
                                }
                            >
                                &nbsp;&nbsp;+<strong>{phoneNumber}</strong>
                            </LanguageButton>
                        </Box>
                        <Box mt={4}>
                            <Box
                                mt={1}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <ButtonPhone onClick={() => handlePhoneNumber(1)}>1</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(2)}>2</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(3)}>3</ButtonPhone>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <ButtonPhone onClick={() => handlePhoneNumber(4)}>4</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(5)}>5</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(6)}>6</ButtonPhone>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <ButtonPhone onClick={() => handlePhoneNumber(7)}>7</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(8)}>8</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(9)}>9</ButtonPhone>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <IconButton aria-label="delete" onClick={handleDeleteLastDigit}>
                                    <BackspaceOutlinedIcon />
                                </IconButton>
                                <ButtonPhone onClick={() => handlePhoneNumber(0)}>0</ButtonPhone>
                                <ButtonPhone
                                    sx={{
                                        backgroundColor: state === 'calling' ? 'red' : '#2EB777',
                                        color: 'white',
                                    }}
                                    onClick={state === 'calling' ? handleHangup : handleCall}
                                >
                                    <CallIcon />
                                </ButtonPhone>
                            </Box>
                            <br />
                            <br />
                        </Box>
                    </Paper>
                </Box>
            </main>
        </div>
    );
}

export default TwillioCall;
