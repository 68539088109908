import { Device } from '@twilio/voice-sdk';
import axios from 'axios';

var Twillio = (function () {
    const url = 'https://phlox-pointer-4752.twil.io/capability-token';
    let device = null;
    let connection = null;

    /**
     * Connect to twillio device
     */
    function connect() {
        axios
            .get(url)
            .then((data) => {
                device = new Device(data.data.token);

                device.on('ready', function (device) {
                    console.log('Twilio.Device Ready!');
                });

                device.on('error', function (error) {
                    console.log('Twilio.Device Error: ' + error.message);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });

                device.on('connect', function (conn) {
                    connection = conn;
                    console.log('Successfully established call!');
                });

                device.on('disconnect', function (conn) {
                    connection = null;

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    /**
     * Disconnect from twillio device
     */
    function disconnect() {
        if (device) {
            device.disconnectAll();
        }
    }

    /**
     * Disconnect from twillio device
     */
    async function call(phoneNumber) {
        if (device) {
            return device.connect({ params: { To: `+${phoneNumber}` } });
        }
    }

    /**
     * Send dial tone
     * @param   {String}    digit   Digit to call
     */
    function sendDigits(digit) {
        if (device) {
            connection.sendDigits(digit);
        }
    }

    return {
        connect: connect,
        disconnect: disconnect,
        sendDigits: sendDigits,
        call: call,
    };
})();

export default Twillio;
